import {reactive, ref} from 'vue';
import { buttons } from '@/components/MenuNavigation/data/buttons';
import { buttonCode } from '@/components/MenuNavigation/data/buttonCode';
import { category } from '@/components/MenuNavigation/data/category';

import MenuNavigationModel from '@/components/MenuNavigation/types/model';

import type { MenuNavigation } from '@/components/MenuNavigation/types/interfaces';
import type {setButtonNotificationType} from "@/types/menuNavigation";

const menuNavigation:MenuNavigation = {
    activeButton: ref(buttonCode.espaces),
    menuButtons: reactive(buttons.map((data) => data))
}

export function useNavigationData(isAdmin: boolean){
  const findButtonByCode = (code: string) : MenuNavigationModel | null => {
    const menuButton = menuNavigation.menuButtons.find(button => button.code === code)
    if(!menuButton){
      displayWarnMessage(`Button not found : ${code}`);
      return null;
    }

    return menuButton;
  }

  if(isAdmin){
    const button = findButtonByCode(buttonCode.administration)
    if( button ) button.isShown = isAdmin
  }

  const setButtonActive = (code: string): void => {

    const button = findButtonByCode(code);

    if ( 
      button 
      && button.category !== category.options
    ) menuNavigation.activeButton.value = code;
  }

  const setButtonDisplay = (code: string, state: boolean) => {

    const button = findButtonByCode(code);

    if ( button ) button.isShown = state;
  }

  const isButtonActive = ((code:string) => {
    return menuNavigation.activeButton.value === code;
  });

  const setButtonNotification: setButtonNotificationType = (code, state) => {

    const button = findButtonByCode(code);

    if (button) button.hasNotification = state;

  }

  return {
    menuNavigation, 
    isButtonActive, 
    setButtonActive, 
    setButtonNotification, 
    setButtonDisplay, 
    findButtonByCode
  }
}


function displayWarnMessage(message: string){
    console.warn(message)
}
