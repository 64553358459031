import type MenuNavigationModel from '@/components/MenuNavigation/types/model';
import {category} from '@/components/MenuNavigation/data/category'
import {computed, ComputedRef} from "vue";

export function useButtonFilter(buttons: MenuNavigationModel[]) {
    function filter(category: string) :ComputedRef<MenuNavigationModel[]> {
        return computed(() => {
            return buttons.filter(button => button.category === category && button.isShown);
        });
    }

    const optionsButtons = filter(category.options);
    
    const principalButtons = filter(category.principal);
    
    const projetButtons = filter(category.project);
    
    const parameterButtons = filter(category.parameter);

    return {principalButtons, projetButtons, parameterButtons, optionsButtons};
}