<template>
    <div class="category" v-if="props.buttons.length > 0">
        <div class="command-button">
            <template v-for="(button, _index) in props.buttons" :key="_index">
                <div class="menu-button-wrapper">
                    <MenuButton
                        :iconName="button.icon"
                        :iconWeight="isButtonActive(button.code) ? 'fill' : 'bold'"
                        @click="buttonClick(button)"
                        :class="[{active: isButtonActive(button.code)}, `command-button_${button.code}`]"
                        :title="button.title"
                        :aria-label="button.title"
                        :notification="button.hasNotification"
                    ></MenuButton>

                    <slot :name="button.code"></slot>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import MenuButton from '@/components/MenuButton/MenuButton.vue'
import type MenuNavigationModel from '@/components/MenuNavigation/types/model';
import {AppProps} from "@/types/menuNavigation";
import {inject} from "vue";

const props = defineProps<{
    buttons: MenuNavigationModel[]
}>();

import {useNavigation} from '@/composables/useNavigation';
const { startNavigation, redirectToExternMail } = useNavigation()

import { useNavigationData } from '@/composables/useNavigationData';

const appProps = inject('appProps') as AppProps;
const {isButtonActive, setButtonActive} = useNavigationData(appProps.isAdmin);

import {useEnvVariables} from "@/composables/useEnvVariables";
const { environmentVariables } = useEnvVariables();

const buttonClick = (button:MenuNavigationModel) => {
  if(URL.canParse(environmentVariables.webmailUrl.value) && button.code === 'mail') {
    redirectToExternMail(environmentVariables.webmailUrl.value);
  }
  else {
    setButtonActive(button.code);
    startNavigation(button);
  }
}
</script>